/* This CSS document takes elements like P and A and removes the standard styling from them */

a {color: inherit; text-decoration: none;}
a:link {color: inherit; text-decoration: none;}
a:visited {color: inherit;}
a:active {color: inherit;}
a:hover {color: inherit;}

body {
    margin: 0px;
    line-height: 1.2em;
}

p {
    margin: 0px !important; 
    display: inline;
}

section {
    display: inline;
}

header {
    display: inline;
}

nav {
    display: inline;
}

ul {
    display: inline;
    padding: 0px;
    margin: 0px;
}

li {
    display: block;
}

h1,h2,h3,h4,h5,h6 {
    display: inline;
    font-size: inherit;
    font-weight: inherit;
    
    margin: 0px;
}

/* article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
} */