a, a:link {
  color: inherit;
  text-decoration: none;
}

a:visited, a:active, a:hover {
  color: inherit;
}

body {
  margin: 0;
  line-height: 1.2em;
}

p {
  display: inline;
  margin: 0 !important;
}

section, header, nav {
  display: inline;
}

ul {
  margin: 0;
  padding: 0;
  display: inline;
}

li {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  display: inline;
}

/*# sourceMappingURL=index.3543d11b.css.map */
